import getFrontendEventsClient from '../getFrontendEventsClient/getFrontendEventsClient'

const SAMPLING_RATE = 0.5

export default function sendDeprecationEvent() {
  const trackingParams = {
    issue: 'Old osm integration',
    clientId: window.Klarna.OnsiteMessaging.clientId,
    host: window.location.host,
    path: window.location.pathname
  }

  const randomNumber = Math.random()

  if (randomNumber < SAMPLING_RATE) {
    getFrontendEventsClient().event('old_osm_integration', { ...trackingParams })
  }
}