import getDefaultEnv from '../../getDefaultEnv/getDefaultEnv'
import { parse } from '../../querystring/querystring'

const HOST_REGEX = /((https?):|)\/\/(.*)\/merchant.js/

const SCRIPT_TAG_CSS_SELECTOR = process.env.ENVIRONMENT === 'test'
  ? 'script[src*="test"][src*="/merchant.js"]'
  : 'script[src*="klarnaservices"][src*="/merchant.js"],script[src*="pre-purchase"][src*="/merchant.js"]'

export default class ScriptDetectionV1 {
  protected element: HTMLScriptElement

  private query: { [id: string]: string }

  public constructor() {
    this.element = this.element || document.currentScript as HTMLScriptElement || document.querySelector(SCRIPT_TAG_CSS_SELECTOR)
    if (this.element) {
      this.query = parse(this.element.src.split('?').pop()) as { [id: string]: string }
    }
  }

  public get host() {
    if (!this.element) return null

    const matches = this.element.src.match(HOST_REGEX)

    if (!matches) {
      return null
    }

    const host = matches[3]

    return host || ''
  }

  public get originUrl() {
    if (this.element === null) {
      return undefined
    }

    const protocol = 'https'

    return `${protocol}://${this.host}`
  }

  public get clientId() {
    return this.getQueryStringParameterByName('uci') || null
  }

  public get environment() {
    return (this.element?.getAttribute('data-environment') || getDefaultEnv()) as Window['Klarna']['OnsiteMessaging']['environment']
  }

  private getQueryStringParameterByName(queryName: string) {
    return this.query?.[queryName]
  }
}