import OSMWindowExposedSDK from '../../../types/OSMWindowExposedSDK'
import EnvironmentStage from '../../../types/EnvironmentStage'

type Event = {
  eventName: string
}

const events: Event[] = []

class KlarnaOnsiteService extends Array<Event> {
  public clientId?: string

  public loaded?: boolean

  public originUrl?: string

  public version?: string

  public host?: string

  public sessionId?: string

  pop() {
    return events.pop()
  }

  push(event: any) {
    events.push(event)
    switch (event.eventName) {
      case 'refresh-placements':
        break
      default:
        break
    }
    return events.length
  }
}

export default new KlarnaOnsiteService()

// Exposed as window.Klarna.OnsiteMessaging
// eslint-disable-next-line @typescript-eslint/naming-convention
export const OnsiteMessaging: OSMWindowExposedSDK = {
  clientId: undefined,
  loaded: false,
  originUrl: undefined,
  version: process.env.APP_VERSION || 'undefined',
  host: undefined,
  numberOfRefreshPlacements: 0,
  integrationVersion: 'v1',
  refresh() {
    this.numberOfRefreshPlacements += 1
  },
  environment: (process.env.ENVIRONMENT) as EnvironmentStage
}
