import ScriptDetectionV1 from './utils/ScriptDetection/v1/ScriptDetectionV1'
import { markEndExecution, markStartExecution } from './tracking/trackExecutionTime/trackExecutionTime'
import * as logger from './utils/logger/logger'
import KlarnaOnsiteService, { OnsiteMessaging } from './utils/KlarnaOnsiteService/v1/KlarnaOnSiteService'
import sendDeprecationEvent from './tracking/sendDeprecationEvent/sendDeprecationEvent'

markStartExecution()

export const merchantScript = new ScriptDetectionV1()

if (merchantScript.clientId) {
  sendDeprecationEvent()
  /* eslint-disable max-len */
  logger.error(`You are using an old integration of On-site Messaging that has been deprecated.
    \n For more info: https://docs.klarna.com/on-site-messaging/in-depth-knowledge/on-site-messaging-javascript-library/#how-can-we-migrate-to-the-new-osm-integration`)
  /* eslint-enable max-len */

  // eslint-disable-next-line
  /* global __webpack_public_path__:writable */
  if (process.env.ENVIRONMENT !== 'development' && process.env.ENVIRONMENT !== 'test') {
    __webpack_public_path__ = `https://${merchantScript.host}/`
  }

  if (!(`${process.env.ENVIRONMENT}`.indexOf('production') > -1)) {
    logger.warn(`You are using a non-production suitable integration of On-site Messaging.
      \n If this is your production environment please check https://docs.klarna.com/on-site-messaging/osm-go-live-check-list/`)
  }

  logger.warn(
    `The Klarna library you are utilizing will be deprecated end of 2024.\n
    We strongly recommend updating your integration for continued support and enhanced features.\n
    For migration details, please visit: https://docs.klarna.com/on-site-messaging/migrate-to-new-klarna-websdk/"`
  )

  // This exposed object needs to be deprecated, supported here for backwards compatibility.
  window.KlarnaOnsiteService = KlarnaOnsiteService
  window.kudt = KlarnaOnsiteService

  window.Klarna = window.Klarna || { OnsiteMessaging }
  window.Klarna.OnsiteMessaging = OnsiteMessaging
  window.Klarna.OnsiteMessaging.clientId = merchantScript.clientId
  window.Klarna.OnsiteMessaging.environment = merchantScript.environment
  window.Klarna.OnsiteMessaging.originUrl = merchantScript.originUrl
  window.Klarna.OnsiteMessaging.integrationVersion = 'v1'
  window.Klarna.OnsiteMessaging.host = merchantScript.host
  window.Klarna.OnsiteMessaging.loaded = window.Klarna.OnsiteMessaging.loaded || false

  if (!window.Klarna.OnsiteMessaging.loaded) {
    window.Klarna.OnsiteMessaging.loaded = true
  }

  markEndExecution()
}
